@import '~normalize.css/normalize.css';

@font-face {
  font-family: PlutoSansRegular;
  src: url(./assets/fonts/PlutoSansDPDRegular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('./assets/fonts/roboto/Robotothin.woff2') format('woff2'),
    url('./assets/fonts/roboto/Robotothin.woff') format('woff'),
    url('./assets/fonts/roboto/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
    url('./assets/fonts/roboto/Robotolight.woff2') format('woff2'),
    url('./assets/fonts/roboto/Robotolight.woff') format('woff'),
    url('./assets/fonts/roboto/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('./assets/fonts/roboto/Roboto.woff2') format('woff2'),
    url('./assets/fonts/roboto/Roboto.woff') format('woff'),
    url('./assets/fonts/roboto/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./assets/fonts/roboto/Robotomedium.woff2') format('woff2'),
    url('./assets/fonts/roboto/Robotomedium.woff') format('woff'),
    url('./assets/fonts/roboto/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./assets/fonts/roboto/Robotobold.woff2') format('woff2'),
    url('./assets/fonts/roboto/Robotobold.woff') format('woff'),
    url('./assets/fonts/roboto/Robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

#root {
  display: flex;
  min-height: 100vh;
}

html {
  font-size: calc(16px + (36 - 16) * ((100vw - 2000px) / (4200 - 2000)));
}

@media screen and (max-width: 2000px) {
  html {
    font-size: 16px !important;
  }
}

@media print {
  :not(.print) {
    visibility: hidden !important;
    padding: 0;
    margin: 0;
  }
  .print * {
    visibility: visible !important;
  }
}

#recaptcha {
  position: relative;
  z-index: 999999;
}
